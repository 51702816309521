import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/HomeView.vue'),
    meta:{
      title:"钱满屋"
    }
  },
  {
    path: '/advertise',
    component: () => import('@/views/AdvertiseView.vue')
  },
  {
    path: '/app',
    component: () => import('@/views/AppView.vue')
  },
  {
    path: '/jianYou',
    component: () => import('@/views/AppJianYouView.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "钱满屋" ;
  next();
})

export default router
